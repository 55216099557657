<template>
  <v-container>
    <back-toolbar title="search.search"></back-toolbar>
    <v-row>
      <v-col cols="12">
        <v-text-field
          solo
          dense
          hide-details
          clearable
          height="44"
          class="main-search"
          :style="
            $store.state.settings.dark
              ? 'background-color: #272727; color: #ffffff'
              : ''
          "
          v-model="searchText"
          :placeholder="$t('search.what')"
        >
          <template v-slot:prepend-inner>
            <img
              width="24"
              height="24"
              :src="require('@/assets/search/magnify.svg')"
              :class="$store.state.settings.dark ? 'filter' : ''"
            />
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!$store.state.isMiKY">
      <v-col cols="12">
        <v-toolbar height="44px" style="border-radius: 10px" elevation="2">
          <v-menu offset-y content-class="location-menu">
            <template v-slot:activator="{ on }">
              <v-autocomplete
                class="location-select"
                solo
                dense
                flat
                hide-details
                hide-no-data
                return-object
                clearable
                :label="$t('search.where')"
                v-model="where"
                :items="locationEntries"
                :search-input.sync="locationSearchText"
                item-text="value"
                item-value="id"
                v-on="on"
                :class="$store.state.settings.dark ? 'dark' : 'light'"
                @blur="blur"
                @click:clear="clear($event)"
              >
                <template v-slot:prepend>
                  <img
                    width="24"
                    height="24"
                    :src="require('@/assets/search/locationMarker.svg')"
                  />
                </template>
              </v-autocomplete>
            </template>
          </v-menu>
        </v-toolbar>
        <v-list v-if="showEntries">
          <v-list-item
            v-for="(item, index) in locationEntries"
            :key="index"
            @click="selectLocation(item), (where = item)"
          >
            <v-list-item-title>{{ item.value }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="right">
        <v-img
          width="100px"
          contain
          :src="require('@/assets/search/powered_by_google_on_white.png')"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <template>
        <!-- Search Results -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="pr-0 py-0">
              <v-layout style="overflow: hidden; overflow-x: auto">
                <div
                  v-for="(item, i) in filteredCategories"
                  :key="'cat-' + i"
                  class="pt-2"
                >
                  <div v-if="item.pictogram">
                    <v-btn
                      :class="
                        item.id === selectedCategory || !selectedCategory
                          ? 'ml-2 mr-2 gradient'
                          : 'ml-2 mr-2'
                      "
                      fab
                      depressed
                      @click="filterByCategory(item)"
                    >
                      <img
                        :class="
                          item.id === selectedCategory || !selectedCategory
                            ? 'filter'
                            : 'filterBlack'
                        "
                        width="30px"
                        height="auto"
                        :src="item.pictogram"
                        style="top: 2px; left: 2px"
                      />
                    </v-btn>
                    <div
                      style="
                        word-break: break-word;
                        max-width: 64px;
                        white-space: normal;
                      "
                      class="Text-Dark-Text-4---12-Center pt-2 ml-1 pb-2"
                    >
                      {{ $t(`categories.${item.title}`) }}
                    </div>
                  </div>
                </div>
              </v-layout>
            </v-col>
          </v-row>
          <error-image
            v-if="
              !loading &&
              (!$store.state.recommendations.searchResults ||
                $store.state.recommendations.searchResults.length === 0)
            "
            height="calc(100vh - 450px)"
            :text="$t('search.noResults')"
            :imageName="'noResults.png'"
          ></error-image>
          <v-list>
            <v-list-item
              class="px-0"
              v-for="(item, index) in filteredItems"
              :key="'result' + index"
              @click="
                $router.safePush({
                  name: 'detail',
                  params: {
                    id: item.id,
                  },
                })
              "
            >
              <v-list-item-icon
                class="mr-3 gradient pa-1 rounded-circle mt-2"
                style="width: 30px; height: 30px"
              >
                <v-img
                  v-if="item.category && item.category.pictogram"
                  class="filter"
                  width="20"
                  height="auto"
                  :src="item.category.pictogram"
                />
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="Text-Dark-Text-2---16-Left-Align"
                  v-if="item.header"
                  >{{ item.header }}</v-list-item-title
                >
                <v-list-item-subtitle
                  class="Text-Dark-Text-3---14-Left-Align"
                  v-if="item.location.address"
                  style="color: #918e8e !important"
                >
                  {{ item.location.address }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                v-if="item.distance"
                class="Text-Dark-Text-3---14-Left-Align mb-0"
                style="max-width: 30%; color: #918e8e !important"
              >
                <span>
                  {{ (item.distance / 1000).toFixed(1) }} km
                  <v-img
                    class="d-inline-block"
                    :class="$store.state.settings.dark ? 'filter' : ''"
                    height="25"
                    width="24"
                    :src="require('@/assets/distance.svg')"
                /></span>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="loading">
      <loading></loading>
    </v-row>

    <!-- Scroll to top button -->
    <v-btn
      class="gradient scroll-button px-0"
      v-show="scY > 300"
      @click="toTop"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="arcs"
      >
        <path d="M18 15l-6-6-6 6" />
      </svg>
    </v-btn>
  </v-container>
</template>

<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import { mapMutations, mapGetters } from "vuex";
import { GetSuggestions } from "@/utils/PlaceUtils";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import orderBy from "lodash/orderBy";
import ErrorImage from "../components/app/common/ErrorImage.vue";
import Loading from "@/components/app/common/Loading";
import geocoding from "reverse-geocoding";
export default {
  beforeMount() {
    if (this.$router.from.name !== "detail") {
      this.textSearch();
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
    if (this.$store.state.session.pageYOffset) {
      window.scrollTo({
        top: this.$store.state.session.pageYOffset,
      });
    }

    // click on search bar if search bar in dashboard has been clicked before.
    if (this.$route.params?.searchBarClicked) {
      document.querySelector(".main-search input").focus();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    if (this.$router.past[this.$router.past.length - 1].name !== "detail") {
      this.$store.commit("session/SET_PAGEYOFFSET", undefined);
    }
  },
  data() {
    return {
      value: undefined,
      searchText: "",
      where: [],
      locationSearchText: null,
      locationEntries: [],
      showEntries: false,
      getMore: false,
      scTimer: 0,
      scY: 0,
      lat: this.$store.state.search.searchLocation?.lat
        ? this.$store.state.search.searchLocation.lat
        : this.$store.state.map.userPosition.lat,
      lng: this.$store.state.search.searchLocation?.lng
        ? this.$store.state.search.searchLocation.lng
        : this.$store.state.map.userPosition.lng,
      city: this.$store.state.search.searchLocation?.city
        ? this.$store.state.search.searchLocation.city
        : this.$store.state.session.currentCity,
      selectedCategory: undefined,
    };
  },
  components: {
    BackToolbar,
    ErrorImage,
    Loading,
  },
  computed: {
    filteredCategories() {
      let categories = [];
      if (this.$store.state.recommendations.searchResults) {
        this.$store.state.recommendations.searchResults.forEach((item) => {
          if (item.category) {
            if (
              findIndex(categories, (category) => {
                return category.title === item.category.title;
              }) === -1
            ) {
              categories.push(item.category);
            }
          }
        });
      }
      return categories;
    },
    filteredItems() {
      return orderBy(
        filter(this.$store.state.recommendations.searchResults, (item) => {
          return (
            (item.category && item.category.id === this.selectedCategory) ||
            !this.selectedCategory
          );
        }),
        ["distance"],
        ["asc"]
      );
    },
    loading() {
      return this.$store.state.recommendations.searchStatus === "fetching";
    },
    hasAllItems() {
      return this.$store.state.recommendations.searchStatus === "failure";
    },
    search() {
      let search = [];
      let input = this.searchText?.match(/\w+/g);
      if (input?.length) {
        input.forEach((word) => {
          search.push(word.toLowerCase());
        });
      }
      if (search.length === 0) {
        return "";
      }
      return search;
    },
  },
  methods: {
    filterByCategory(category) {
      if (this.selectedCategory !== category.id) {
        this.selectedCategory = category.id;
      } else {
        this.selectedCategory = undefined;
      }
    },
    ...mapMutations({
      setSearchResults: "search/SET_SEARCH_RESULTS",
    }),
    saveSearchLocation() {
      this.$store.commit("search/SET_SEARCH_LOCATION", {
        where: this.where,
        locationEntries: this.locationEntries,
        city: this.city,
        lat: this.lat,
        lng: this.lng,
      });
      this.textSearch();
    },
    async textSearch() {
      if (this.search || this.city) {
        this.$store.commit("search/SET_SEARCH_TEXT", this.searchText);
        this.$store.dispatch("recommendations/getSearchResults", {
          city: this.city,
          lat: this.lat,
          lng: this.lng,
          search: this.search,
        });
      } else {
        this.$store.commit("search/SET_SEARCH_TEXT", this.searchText);
        this.$store.commit("recommendations/RESET_SEARCH_RESULTS");
      }
    },
    blur() {
      setTimeout(() => {
        this.showEntries = false;
      }, 300);
    },
    clear(event) {
      this.selectLocation(undefined);
      this.locationSearchText = "";
      this.locationEntries = [];
      this.where = "";
      this.city = "";
      event.preventDefault();
    },
    async loadMore() {
      if (
        this.hasAllItems ||
        this.loading ||
        this.getMore ||
        (this.search == "" && this.city == "")
      ) {
        return;
      }
      this.getMore = true;
      await this.$store.dispatch("recommendations/getMoreSearchResults", {
        city: this.city,
        lat: this.lat,
        lng: this.lng,
        search: this.search,
      });
    },
    // Setting the location
    async setLocation({ lat, lng, city }) {
      this.lat = lat;
      this.lng = lng;
      this.city = city;
    },
    selectLocation(place) {
      if (!place?.id) {
        this.lat = this.$store.state.map.userPosition.lat;
        this.lng = this.$store.state.map.userPosition.lng;
        this.city = this.$store.state.session.currentCity;
      } else {
        const geocoder = new google.maps.Geocoder();
        // Turns the place id into latitude and longitude
        geocoder.geocode({ placeId: place.id }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              var newLocation = {};
              newLocation.lat = results[0].geometry.location.lat();
              newLocation.lng = results[0].geometry.location.lng();
              results[0].address_components.forEach(function (element2) {
                element2.types.forEach(function (element3) {
                  switch (element3) {
                    case "locality":
                      newLocation.city = element2.long_name;
                      break;
                  }
                });
              });
              this.showEntries = false;
              this.setLocation(newLocation);
            }
          } else {
            console.error("Failed to get Lat and Long from place Id");
          }
        });
      }
    },
    async onScroll() {
      if (
        window.pageYOffset === 0 &&
        this.$store.state.session.pageYOffset > 30
      ) {
        window.scrollTo({
          top: this.$store.state.session.pageYOffset,
        });
        return;
      }
      this.$store.commit("session/SET_PAGEYOFFSET", window.pageYOffset);
      // Load more if close to the bottom and not loading
      if (
        !this.getMore &&
        this.$store.state.recommendations.searchResults?.length > 0 &&
        !this.hasAllItems &&
        !this.loading &&
        window.pageYOffset + window.innerHeight >=
          document.body.scrollHeight - 20
      ) {
        await this.loadMore();
      }

      // update fields for scroll to top button
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  // show search history and hide previous results
  beforeRouteLeave(to, from, next) {
    // Resetting position when leaving the search
    // unless you click on a search result
    // or you did not change the location
    if (from.name === "search" && to.name !== "detail") {
      this.$store.commit("search/SET_SEARCH_LOCATION", undefined);
      this.$store.commit("search/SET_SEARCH_TEXT", undefined);
    }
    next();
  },
  watch: {
    locationSearchText(newVal) {
      // Search only after at least 1 character has been typed to prevent error in console
      if (!newVal || newVal.length < 1) {
        this.showEntries = false;
        return;
      }
      if (newVal.length > 1) {
        this.showEntries =
          document.querySelector(".v-autocomplete input") ===
          document.activeElement; // Show entries if the input field is focused.
        GetSuggestions(newVal)
          .then((response) => {
            // if condition is necessary to prevent a null value in the dropdown menu
            if (response) {
              this.locationEntries = response;
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    // Search when searchtext changed
    searchText() {
      this.textSearch();
    },
    // Save the search location if it changes
    where() {
      this.saveSearchLocation();
    },
    lat() {
      this.saveSearchLocation();
    },
    "$store.state.recommendations": {
      handler(val) {
        if (val?.searchResults?.length < 10) {
          this.loadMore();
        }
      },
      deep: true,
    },
    filteredItems(newVal) {
      if (newVal.length < 10) {
        this.loadMore();
      }
    },
    loading(val) {
      if (!val) {
        this.getMore = false;
      }
    },
  },
};
</script>

<style>
.main-search {
  border-radius: 8px;
}
.main-search .v-input__control .v-input__slot .v-text-field__slot input {
  padding-left: 5px;
}
.main-search input,
.main-search input::placeholder,
.location-select input,
.location-select .v-select__slot label {
  color: #606060 !important;
}
.location-menu {
  background-color: transparent;
  box-shadow: none !important;
}
.v-menu__content.v-autocomplete__content .v-list.v-select-list,
.location-select .mdi-menu-down {
  display: none !important;
}
.location-select .mdi-menu-down {
  display: none;
}
.location-select .v-input__slot {
  padding: 0 !important;
}
.location-select label {
  color: rgba(0, 0, 0, 0.38) !important;
}
.location-select .v-select__slot > .v-input__append-inner {
  position: absolute;
  right: 0;
  top: 0;
}
.location-select .v-input__icon.v-input__icon--append {
  display: none;
}
.location-select .v-icon.mdi-close.primary--text {
  color: rgba(0, 0, 0, 0.54) !important;
}
div.v-select__slot {
  padding-right: 30px;
}
.v-divider {
  border-color: rgba(208, 201, 214, 1) !important;
}
</style>
<style lang="scss" scoped>
.scroll-button {
  border-radius: 10px;
  position: fixed;
  bottom: calc(70px + env(safe-area-inset-bottom));
  right: 20px;
  min-width: 36px !important;
  max-width: 36px !important;
  cursor: pointer;
}
</style>
