var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('back-toolbar',{attrs:{"title":"search.search"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"main-search",style:(_vm.$store.state.settings.dark
            ? 'background-color: #272727; color: #ffffff'
            : ''),attrs:{"solo":"","dense":"","hide-details":"","clearable":"","height":"44","placeholder":_vm.$t('search.what')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('img',{class:_vm.$store.state.settings.dark ? 'filter' : '',attrs:{"width":"24","height":"24","src":require('@/assets/search/magnify.svg')}})]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),(!_vm.$store.state.isMiKY)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{staticStyle:{"border-radius":"10px"},attrs:{"height":"44px","elevation":"2"}},[_c('v-menu',{attrs:{"offset-y":"","content-class":"location-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-autocomplete',_vm._g({staticClass:"location-select",class:_vm.$store.state.settings.dark ? 'dark' : 'light',attrs:{"solo":"","dense":"","flat":"","hide-details":"","hide-no-data":"","return-object":"","clearable":"","label":_vm.$t('search.where'),"items":_vm.locationEntries,"search-input":_vm.locationSearchText,"item-text":"value","item-value":"id"},on:{"update:searchInput":function($event){_vm.locationSearchText=$event},"update:search-input":function($event){_vm.locationSearchText=$event},"blur":_vm.blur,"click:clear":function($event){return _vm.clear($event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('img',{attrs:{"width":"24","height":"24","src":require('@/assets/search/locationMarker.svg')}})]},proxy:true}],null,true),model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}},on))]}}],null,false,844914571)})],1),(_vm.showEntries)?_c('v-list',_vm._l((_vm.locationEntries),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.selectLocation(item), (_vm.where = item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.value))])],1)}),1):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"right"}},[_c('v-img',{attrs:{"width":"100px","contain":"","src":require('@/assets/search/powered_by_google_on_white.png')}})],1)],1),_c('v-row',[[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pr-0 py-0",attrs:{"cols":"12"}},[_c('v-layout',{staticStyle:{"overflow":"hidden","overflow-x":"auto"}},_vm._l((_vm.filteredCategories),function(item,i){return _c('div',{key:'cat-' + i,staticClass:"pt-2"},[(item.pictogram)?_c('div',[_c('v-btn',{class:item.id === _vm.selectedCategory || !_vm.selectedCategory
                        ? 'ml-2 mr-2 gradient'
                        : 'ml-2 mr-2',attrs:{"fab":"","depressed":""},on:{"click":function($event){return _vm.filterByCategory(item)}}},[_c('img',{class:item.id === _vm.selectedCategory || !_vm.selectedCategory
                          ? 'filter'
                          : 'filterBlack',staticStyle:{"top":"2px","left":"2px"},attrs:{"width":"30px","height":"auto","src":item.pictogram}})]),_c('div',{staticClass:"Text-Dark-Text-4---12-Center pt-2 ml-1 pb-2",staticStyle:{"word-break":"break-word","max-width":"64px","white-space":"normal"}},[_vm._v(" "+_vm._s(_vm.$t(("categories." + (item.title))))+" ")])],1):_vm._e()])}),0)],1)],1),(
            !_vm.loading &&
            (!_vm.$store.state.recommendations.searchResults ||
              _vm.$store.state.recommendations.searchResults.length === 0)
          )?_c('error-image',{attrs:{"height":"calc(100vh - 450px)","text":_vm.$t('search.noResults'),"imageName":'noResults.png'}}):_vm._e(),_c('v-list',_vm._l((_vm.filteredItems),function(item,index){return _c('v-list-item',{key:'result' + index,staticClass:"px-0",on:{"click":function($event){return _vm.$router.safePush({
                name: 'detail',
                params: {
                  id: item.id,
                },
              })}}},[_c('v-list-item-icon',{staticClass:"mr-3 gradient pa-1 rounded-circle mt-2",staticStyle:{"width":"30px","height":"30px"}},[(item.category && item.category.pictogram)?_c('v-img',{staticClass:"filter",attrs:{"width":"20","height":"auto","src":item.category.pictogram}}):_vm._e()],1),_c('v-list-item-content',{staticClass:"py-0"},[(item.header)?_c('v-list-item-title',{staticClass:"Text-Dark-Text-2---16-Left-Align"},[_vm._v(_vm._s(item.header))]):_vm._e(),(item.location.address)?_c('v-list-item-subtitle',{staticClass:"Text-Dark-Text-3---14-Left-Align",staticStyle:{"color":"#918e8e !important"}},[_vm._v(" "+_vm._s(item.location.address)+" ")]):_vm._e()],1),(item.distance)?_c('v-list-item-action',{staticClass:"Text-Dark-Text-3---14-Left-Align mb-0",staticStyle:{"max-width":"30%","color":"#918e8e !important"}},[_c('span',[_vm._v(" "+_vm._s((item.distance / 1000).toFixed(1))+" km "),_c('v-img',{staticClass:"d-inline-block",class:_vm.$store.state.settings.dark ? 'filter' : '',attrs:{"height":"25","width":"24","src":require('@/assets/distance.svg')}})],1)]):_vm._e()],1)}),1)],1)]],2),(_vm.loading)?_c('v-row',[_c('loading')],1):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.scY > 300),expression:"scY > 300"}],staticClass:"gradient scroll-button px-0",on:{"click":_vm.toTop}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"40","height":"40","viewBox":"0 0 24 24","fill":"none","stroke":"#fff","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"arcs"}},[_c('path',{attrs:{"d":"M18 15l-6-6-6 6"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }